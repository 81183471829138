/*--------------------------------------------------------------
7. Header
----------------------------------------------------------------*/

.cs_site_header {
    position: relative;
    z-index: 101;
}

.cs_site-branding {
    display: inline-block;
    max-width: 180px;
}

.cs_site_header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0px;
    transition: all 0.4s ease;
}

.cs_site_header.cs_style_1 {
    .cs_main_header_in,
    .cs_top_header_in {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 90px;
        position: relative;
    }
    .cs_main_header_right {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 30px;
        @media (max-width: 1199px) {
            padding-right: 50px;
        }
    }
    @media (max-width: 1199px) {
        .cs_btn.cs_style_1 {
            display: none;
        }
    }
}

.cs_site_header.cs_style_1 {
    &.cs_sticky_active {
        background-color: $white;
        box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
    }
    .cs_action_box {
        display: flex;
        align-items: center;
        .cs_action_value {
            margin-left: 15px;
        }
        >* {
            &:not(:last-child) {
                margin-right: 35px;
            }
        }
    }
    &.cs_color_1 {
        .cs_nav_list {
            >li>a {
                color: #fff;
            }
        }
        &.cs_gescout_show {
            background-color: $primary;
        }
    }
}

.cs_site_header_full_width .container {
    max-width: 100%;
    padding: 0 100px;
}

.cs_site_header_style1 {
    border-bottom: 1px solid #1a188d;
    .cs_main_header_right {
        display: flex;
        align-items: center;
    }
    .cs_nav+.cs_header_toolbox.cs_center {
        margin-left: 35px;
    }
    .cs_nav .cs_nav_list>li.current-menu-item>a:before {
        bottom: -4px;
        background-color: $white;
    }
}

@media screen and (max-width: 1199px) {
    .cs_main_header .container {
        max-width: 100%;
    }
    .cs_site_header.cs_style_1 {
        .cs_nav {
            display: flex;
        }
    }
}

.cs_nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cs_sticky_header {
    position: fixed !important;
    width: 100%;
    z-index: 999;
}

.cs_gescout_sticky {
    position: fixed !important;
    top: -110px;
    opacity: 0;
    transition: all 0.4s ease;
    background-color: $white;
}

.cs_gescout_show {
    top: 0 !important;
    opacity: 1;
    box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
}

.cs_site_branding {
    display: inline-block;
    img {
        max-height: 45px;
    }
}

@media screen and (min-width: 1200px) {
    .cs_main_header {
        position: relative;
        .container-fluid {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
    .cs_main_header_center,
    .cs_top_header_center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .cs_site_header.cs_style_1 {
        .cs_main_header_center {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            max-width: calc(100% - 300px);
        }
        .cs_main_header_left {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .cs_nav {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: 1.6em;
        .cs_nav_list {
            display: flex !important;
            flex-wrap: wrap;
            height: inherit;
            >li {
                margin-right: 40px;
                height: inherit;
                &:last-child {
                    margin-right: 0;
                }
                >a {
                    padding: 10px 0;
                    display: inline-flex;
                    position: relative;
                    height: inherit;
                    align-items: center;
                }
                >ul {
                    left: 0;
                    top: calc(100% + 15px);
                    &::before {
                        content: '';
                        position: absolute;
                        height: 10px;
                        width: 10px;
                        background-color: #fff;
                        transform: rotate(45deg);
                        left: 20px;
                        top: -5px;
                        border-radius: 2px 0 0 0;
                    }
                }
                &:hover {
                    >ul {
                        top: 100%;
                        opacity: 1;
                        visibility: visible;
                        transition: all 0.4s ease;
                    }
                }
                &.menu-item-has-children {
                    >a {
                        position: relative;
                        &::after {
                            content: '';
                            display: inline-block;
                            height: 6px;
                            width: 6px;
                            border: 2px solid currentColor;
                            transform: rotate(45deg);
                            border-left: 0;
                            border-top: 0;
                            margin-left: 6px;
                            position: relative;
                            top: -1px;
                            border-radius: 0px 0px 2px 0px;
                        }
                    }
                }
            }
            li:not(.cs_mega_menu) {
                position: relative;
            }
            ul {
                width: 260px;
                background-color: $white;
                position: absolute;
                box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
                padding: 10px 0;
                z-index: 100;
                opacity: 0;
                visibility: hidden;
                display: block !important;
                border-radius: 15px;
                transition: all 0.1s ease;
                li {
                    &:hover {
                        ul {
                            top: 0px;
                        }
                        >ul {
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.4s ease;
                        }
                    }
                }
                a {
                    display: block;
                    line-height: inherit;
                    padding: 10px 20px;
                }
                ul {
                    top: 15px;
                    left: 100%;
                }
            }
        }
    }
    .cs_menu_toggle,
    .cs_munu_dropdown_toggle {
        display: none;
    }
    .cs_nav .cs_nav_list {
        .cs_mega_menu {
            position: relative;
        }
        .cs_mega_wrapper {
            width: 475px !important;
            left: 0;
            display: flex !important;
            position: absolute;
            padding: 5px 15px 10px;
            a {
                padding: 7px 10px;
            }
            >li {
                flex: 1;
                padding: 10px 0;
                >a {
                    font-size: 18px;
                    font-weight: 600;
                    pointer-events: none;
                    &:hover {
                        background-color: transparent;
                    }
                }
                ul {
                    position: initial;
                    border: none;
                    padding: 0;
                    width: 100%;
                    box-shadow: none;
                    background-color: transparent;
                    a {
                        transition: all 0.4s ease;
                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
        .cs_mega_menu {
            &:hover {
                .cs_mega_wrapper {
                    li ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    .cs_nav .cs_nav_list>li ul:not(.cs_mega_wrapper) .menu-item-has-children>a {
        position: relative;
    }
}

.cs_header_cart {
    position: relative;
}

@media screen and (max-width: 1400px) {
    .cs_nav .cs_nav_list .cs_mega_wrapper {
        width: 1116px !important;
    }
    .cs_site-branding {
        max-width: 190px;
    }
    .cs_site_header_full_width .container {
        max-width: 100%;
    }
}

@media screen and (max-width: 1199px) {
    .cs_nav .cs_nav_list .cs_mega_wrapper {
        width: 100% !important;
    }
    .cs_site_header_full_width .container {
        padding: 0 15px;
    }
    .cs_munu_dropdown_toggle {
        position: absolute;
        height: 40px;
        width: 100%;
        top: 0;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 23px 18px;
        cursor: pointer;
        z-index: 3;
        span {
            display: block;
            position: relative;
            height: 10px;
            width: 10px;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 2px;
                width: 10px;
                background-color: currentColor;
                transition: all 0.3s ease;
            }
            &:before {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
        &.active {
            span {
                &:before {
                    transform: translate(-50%, -50%) rotate(0deg);
                }
            }
        }
    }
    .menu-item-has-children {
        .menu-item-has-children {
            .cs_munu_dropdown_toggle {
                padding: 20px 18px;
            }
        }
    }
    .cs_site_branding {
        position: relative;
        z-index: 101;
    }
    .cs_nav {
        .cs_nav_list {
            position: fixed;
            width: 100vw;
            left: -100vw;
            background-color: #fff;
            color: $primary;
            padding: 10px 0;
            top: 0;
            overflow: auto;
            min-height: 100vh;
            line-height: 1.6em;
            padding-top: 80px;
            transition: all 0.4s ease;
            &.cs_active {
                left: 0vw;
            }
            ul {
                padding-left: 15px;
                display: none;
            }
            a {
                display: block;
                padding: 12px 15px;
                line-height: 16px;
            }
            >li {
                >a {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
        .menu-item-has-children {
            position: relative;
        }
    }
    .cs_animo_links>li>a {
        .cs_animo_text {
            text-shadow: 0 32px 0 currentColor;
        }
        &:hover span {
            transform: translateY(-32px);
        }
    }
    /*Mobile Menu Button*/
    .cs_menu_toggle {
        display: inline-block;
        width: 30px;
        height: 27px;
        cursor: pointer;
        position: absolute;
        top: 27px;
        right: 30px;
        display: none;
        span,
        span:before,
        span:after {
            width: 100%;
            height: 2px;
            background-color: currentColor;
            display: block;
        }
        span {
            margin: 0 auto;
            position: relative;
            top: 12px;
            transition-duration: 0s;
            transition-delay: 0.2s;
            &:before {
                content: '';
                position: absolute;
                margin-top: -9px;
                transition-property: margin, transform;
                transition-duration: 0.2s;
                transition-delay: 0.2s, 0s;
            }
            &:after {
                content: '';
                position: absolute;
                margin-top: 9px;
                transition-property: margin, transform;
                transition-duration: 0.2s;
                transition-delay: 0.2s, 0s;
            }
        }
    }
    .cs_site_header.cs_style_1.cs_color_1 {
        .cs_nav_list {
            background-color: $primary;
            color: #fff;
        }
        .cs_menu_toggle {
            color: #fff;
        }
    }
    .cs_site_header.cs_style_1 .cs_menu_toggle {
        top: 50%;
        right: 0px;
        margin-top: -13px;
    }
    .cs_toggle_active {
        span {
            background-color: rgba(0, 0, 0, 0);
            transition-delay: 0.2s;
            &:before {
                margin-top: 0;
                transform: rotate(45deg);
                transition-delay: 0s, 0.2s;
            }
            &:after {
                margin-top: 0;
                transform: rotate(-45deg);
                transition-delay: 0s, 0.2s;
            }
        }
    }
    .cs_nav .cs_nav_list a {
        position: relative;
    }
    .cs_site_header.cs_style_1 .cs_main_header_in {
        height: 80px;
    }
    .cs_site_header .current-menu-item>a:before {
        display: none;
    }
    .cs_site_header.cs_style_1 .cs_main_header_center {
        .cs_site_branding {
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .cs_site_header.cs_style_1 {
        top: 0;
    }
    .cs_has_main_nav {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .cs_site_header .container {
        max-width: 100%;
    }
    .cs_site_header.cs_style_1 {
        .cs_action_box>*:not(:last-child) {
            margin-right: 25px;
        }
    }
}

@media screen and (max-width: 575px) {
    .cs_site-branding {
        max-width: 150px;
    }
}